<template>
  <div class="footer">
    <div class="item">由杭州亿尚智能科技有限公司提供技术支持</div>
    <div class="item">© 2017-{{ currentYear }} 杭州亿尚智能科技有限公司 版权所有 </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  height: 54px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #888;
}
</style>