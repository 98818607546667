<template>
  <dv-border-box-10 :color="['rgba(46, 96, 153, 1)', 'rgba(124, 231, 253, 1)']">
    <dv-charts :option="option" />
  </dv-border-box-10>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'leftMiddle',
  props: {
    originData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    option: function() {
      let xData = this.originData.map(item => item.v1)
      let yData = this.originData.map(item => item.v2)
      let axisLabelStyle = {
        fill: '#fff',
        fontSize: 14,
      }
      if (xData.length > 4) {
        axisLabelStyle = {
          fill: '#fff',
          fontSize: 12,
          rotate: 20,
          textAlign: 'left',
          textBaseline: 'top'
        }
      }
      return {
        title: {
          text: dayjs().format('M月D日') +  '单日投产数量',
          style: {
            fill: '#fff',
            fontSize: 19,
          }
        },
        xAxis: {
          nameTextStyle: {
            fill: '#fff',
            fontSize: 14,
          },
          axisLine: {
            style: {
              stroke: '#fff'
            }
          },
          axisTick: {
            style: {
              stroke: '#fff'
            }
          },
          axisLabel: {
            style: axisLabelStyle
            // {
            //   fill: '#fff',
            //   fontSize: 12,
            //   rotate: -20,
            // }
          },
          name: '款号',
          data: xData // ['Y-DS1012', 'Y-DS1012', 'Y-DS1012', 'Y-DS1012', 'Y-DS1012', 'Y-DS1012', 'Y-DS1012']
        },
        yAxis: {
          min: 0,
          nameTextStyle: {
            fill: '#fff',
            fontSize: 14
          },
          axisLine: {
            style: {
              stroke: '#fff'
            }
          },
          axisTick: {
            style: {
              stroke: '#fff'
            }
          },
          axisLabel: {
            style: {
              fill: '#fff',
              fontSize: 14
            }
          },
          splitLine: {
            style: {
              stroke: 'rgba(46, 96, 153, 0.3)'
            }
          },
          name: '数量',
          data: 'value'
        },
        series: [
          {
            data: yData, // [80, 60, 35, 80, 30, 40, 70],
            type: 'bar',
            gradient: {
              color: ['#37a2da', '#67e0e3']
            }
          }
        ],
      }
    }
  }
}
</script>

<style scoped>

</style>