<template>
  <div id="app">
    <index />
  </div>
</template>

<script>
import index from './views/index.vue'

export default {
  name: 'App',
  components: {
    index
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  background-color: #000;
}
</style>
