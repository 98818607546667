export default [
  {
    id: 1,
    name: '短外套',
    info: '含外套、小衫、防嗮服等。定义：长度在腰部及其以上，非贴身穿着衣服'
  },
  {
    id: 2,
    name: '衬衣',
    info: '含T恤、衬衣、打底衫等。定义：长度在大腿根部以上，可以贴身穿着衣服'
  },
  {
    id: 3,
    name: '裤装',
    info: '含短裤、长裤、5分裤、裙裤等。定义：有两个裤脚的下装'
  },
  {
    id: 4,
    name: '裙装',
    info: '含短裙、长裙等。定义：下装，双腿在一个裤洞里的衣服'
  },
  {
    id: 5,
    name: '连衣裙',
    info: '定义：上下装一体，下半部分位裙装的服装'
  },
  {
    id: 6,
    name: '长外套',
    info: '含风衣、大衣等。定义：长度在腰部以下，非贴身穿着衣服'
  },
  {
    id: 7,
    name: '套装及其他',
    info: '含套装、连体服、家居服'
  },
]