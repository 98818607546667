import Vue from 'vue'
import App from './App.vue'

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

// 提示toast
import Msg from 'vue-message'
  
// 引入全局css
import './assets/scss/style.scss'

Vue.use(dataV)

Vue.use(Msg, {
  text: '欢迎访问产能数据大屏', position: 'top', duration: 3000, background: 'rgba(13, 128, 243, 0.6)'
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
